import React from "react"

import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { useDesktopMediaQuery } from "decentraland-ui2/dist/components/Media"

import decentralandLogo from "../../images/white-dcl-logo.svg"
import { setUserAgentArchitectureDefautlByOs } from "../../modules/userAgent"
import { LandingDownloadOptions } from "../Landing/LandingDownloadOptions"
import { OperativeSystem } from "../Landing/LandingDownloadOptions/LandingDownloadOptions.types"
import {
  DclWhiteLogo,
  DownloadContainer,
  DownloadImage,
  DownloadImageContainer,
  DownloadPageContainer,
  DownloadSubtitle,
  DownloadTitle,
} from "./DownloadLayout.styled"
import { DownloadLayoutProps } from "./DownloadLayout.types"

const DownloadLayout = React.memo((props: DownloadLayoutProps) => {
  const {
    userAgentData,
    title,
    links,
    redirectPath,
    image,
    imageObjectFit,
    productName,
  } = props

  const l = useFormatMessage()

  const isDesktop = useDesktopMediaQuery()

  const searchParams = new URLSearchParams(window.location.search)
  const os = searchParams.get("os")

  if (userAgentData && os) {
    setUserAgentArchitectureDefautlByOs(userAgentData, os as OperativeSystem)
  }

  return (
    <DownloadPageContainer>
      {isDesktop && (
        <>
          <DownloadContainer>
            <LandingDownloadOptions
              userAgentData={userAgentData}
              links={links}
              title={title}
              redirectPath={redirectPath}
            />
            <DownloadImageContainer>
              <DownloadImage src={image} objectFit={imageObjectFit} />
            </DownloadImageContainer>
          </DownloadContainer>
        </>
      )}
      {!isDesktop && (
        <>
          <DownloadContainer>
            <DclWhiteLogo src={decentralandLogo} />
            <DownloadTitle variant="h3">
              {l("page.download.mobile.title", { product_name: productName })}
            </DownloadTitle>
            <DownloadSubtitle variant="h5">
              {l("page.download.mobile.subtitle")}
            </DownloadSubtitle>
            <iframe
              src="https://embeds.beehiiv.com/d7d652da-adc8-422f-9176-4b653a244020?slim=true"
              data-test-id="beehiiv-embed"
              height="52"
              frameBorder="0"
              scrolling="no"
              width="100%"
              style={{
                margin: 0,
                borderRadius: "0px !important",
                backgroundColor: "transparent",
              }}
            />
          </DownloadContainer>
        </>
      )}
    </DownloadPageContainer>
  )
})

export { DownloadLayout }
