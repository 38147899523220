import { neutral } from "decentraland-ui2/dist/theme/colors"

import { Box, Typography, styled, useTheme } from "decentraland-ui2"

const DownloadPageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  background: "rgb(131, 14, 91)",
  backgroundImage:
    "linear-gradient(332deg, rgba(131, 14, 91, 1) 3%, rgba(46, 1, 62, 1) 35%)",
  flex: 1,
  height: "900px",
  alignItems: "center",
  justifyContent: "center",
})

const DownloadContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(22, 21, 24, 0.5)",
    borderRadius: "32px",
    padding: "72px",
    height: "522px",
    width: "calc(100% - 144px)",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "calc(100% - 32px)",
      padding: "124px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px",
    },
  }
})

const DownloadImageContainer = styled(Box)(() => {
  const theme = useTheme()

  return {
    maxWidth: "900px",
    width: "50%",
    height: "100%",
    overflow: "hidden",
    borderRadius: "24px",
    border: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }
})

const DownloadImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "objectFit",
})((props: { objectFit?: "cover" | "contain" }) => ({
  width: "100%",
  height: "100%",
  objectFit: props.objectFit || "cover",
}))

const DclWhiteLogo = styled("img")({
  height: "48px",
  width: "48px",
})

const DownloadTitle = styled(Typography)(() => {
  const theme = useTheme()
  return {
    marginTop: "8px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.8rem",
    },
  }
})

const DownloadSubtitle = styled(Typography)(() => {
  const theme = useTheme()
  return {
    marginTop: "8px",
    marginBottom: "16px",
    color: neutral.gray4,
    "& span": {
      fontWeight: 700,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }
})

export {
  DownloadPageContainer,
  DownloadContainer,
  DownloadImageContainer,
  DownloadImage,
  DclWhiteLogo,
  DownloadTitle,
  DownloadSubtitle,
}
