import React from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import useAdvancedUserAgentData from "decentraland-gatsby/dist/hooks/useAdvancedUserAgentData"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import env from "decentraland-gatsby/dist/utils/env"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"

import { LandingFooterV2 } from "../components/Landing/LandingFooterV2"
import { DownloadLayout } from "../components/Layout/DownloadLayout"
import Layout from "../components/Layout/Layout"
import downloadImage from "../images/DownloadNow.webp"
import { CDNSource, getCDNRelease } from "../modules/cdnReleases"

import "./download.css"

export default function DownloadPage(props: any) {
  const l = useFormatMessage()

  const [isLoadingUserAgentData, userAgentData] = useAdvancedUserAgentData()

  const links = getCDNRelease(CDNSource.LAUNCHER)

  if (isLoadingUserAgentData || !links) {
    return (
      <Layout {...props} isFullscreen hideFooter={true}>
        <Head
          title={l("page.download.title") || ""}
          description={l("page.download.description") || ""}
          image="https://decentraland.org/images/decentraland.png"
        />
        <Loader active size="large" />
      </Layout>
    )
  }

  return (
    <Layout {...props} isFullscreen hideSocialLinks>
      <Head
        title={l("page.download.title") || ""}
        description={l("page.download.description") || ""}
        image="https://decentraland.org/images/decentraland.png"
      />

      <DownloadLayout
        productName="Decentraland"
        userAgentData={userAgentData}
        title={l("page.download.new_title")}
        links={links}
        image={downloadImage}
        redirectPath={env("DOWNLOAD_SUCCESS_URL", "/download_success")}
      />

      <LandingFooterV2 />
    </Layout>
  )
}
